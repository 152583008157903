import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3a9dc574&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=3a9dc574&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_cache-loader@4.1.0_webpack@4.47.0__css-loader@4.3_255yk7v3heo6y2fxdgr2hz3tmi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9dc574",
  null
  
)

export default component.exports