<template>
  <layout-normal>
    <template #main>
      <section class="practice-area-group-index">
        <div class="headline">
          <h1 class="text">分野別ニュース</h1>
        </div>
        <ul class="grouplist">
          <v-row class="columns" dense>
            <v-col class="column">
              <li v-for="practiceAreaGroup in itemsForFirstColumn" :key="practiceAreaGroup.id" class="group">
                <h2 class="name">{{ practiceAreaGroup.name }}</h2>
                <hr class="separator" />
                <ul class="practiceareas">
                  <li v-for="practiceArea in practiceAreaGroup.practiceAreas" :key="practiceArea.id" class="area">
                    <nuxt-link :to="`/directories/${practiceAreaGroup.id}/${practiceArea.id}`" class="link">
                      <p class="text">{{ practiceArea.name }}</p>
                      <p class="chevron">
                        <v-icon color="#afafaf">mdi-chevron-right</v-icon>
                      </p>
                    </nuxt-link>
                  </li>
                </ul>
              </li>
            </v-col>
            <v-col class="column">
              <li v-for="practiceAreaGroup in itemsForSecondColumn" :key="practiceAreaGroup.id" class="group">
                <h2 class="name">{{ practiceAreaGroup.name }}</h2>
                <hr class="separator" />
                <ul class="practiceareas">
                  <li v-for="practiceArea in practiceAreaGroup.practiceAreas" :key="practiceArea.id" class="area">
                    <nuxt-link :to="`/directories/${practiceAreaGroup.id}/${practiceArea.id}`" class="link">
                      <p class="text">{{ practiceArea.name }}</p>
                      <p class="chevron">
                        <v-icon color="#afafaf">mdi-chevron-right</v-icon>
                      </p>
                    </nuxt-link>
                  </li>
                </ul>
              </li>
            </v-col>
            <v-col class="column">
              <li v-for="practiceAreaGroup in itemsForThirdColumn" :key="practiceAreaGroup.id" class="group">
                <h2 class="name">{{ practiceAreaGroup.name }}</h2>
                <hr class="separator" />
                <ul class="practiceareas">
                  <li v-for="practiceArea in practiceAreaGroup.practiceAreas" :key="practiceArea.id" class="area">
                    <nuxt-link :to="`/directories/${practiceAreaGroup.id}/${practiceArea.id}`" class="link">
                      <p class="text">{{ practiceArea.name }}</p>
                      <p class="chevron">
                        <v-icon color="#afafaf">mdi-chevron-right</v-icon>
                      </p>
                    </nuxt-link>
                  </li>
                </ul>
              </li>
            </v-col>
          </v-row>
        </ul>
      </section>
    </template>
  </layout-normal>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import { Context } from '@nuxt/types';
import { MetaInfo } from 'vue-meta';
import LayoutNormal from '@/components/base/layout-normal.vue';
import DirectoryBetaAlert from '@/components/alert/directory-beta-alert.vue';
import { PracticeAreaGroup } from 'wklr-backend-sdk/models';

type AsyncData = Pick<DirectoriesIndex, 'groups'>;

@Component({ components: { LayoutNormal, DirectoryBetaAlert } })
export default class DirectoriesIndex extends Vue {
  groups: PracticeAreaGroup[] = [];

  head(): MetaInfo {
    return { title: '分野一覧' };
  }

  async asyncData({ app: { $repositories, $auth }, error }: Context): Promise<AsyncData | undefined> {
    if (!$auth.permissions.practiceArea) {
      error({ statusCode: 403, message: 'このページは閲覧できません' });
      return;
    }

    const groups = await $repositories.practiceAreas.getGroups();
    return { groups };
  }

  // itemsForNthColumn では、データの用意ができた分野のみを出します。
  get itemsForFirstColumn(): PracticeAreaGroup[] {
    return [
      {
        id: '1',
        name: 'ファイナンス',
        practiceAreas: new Set([
          // 暫定的ニュース専用分野
          { id: '45', name: 'ファイナンス・ディスクロージャー' },
        ]),
      },
      {
        id: '2',
        name: 'エネインフラ・資源・環境',
        practiceAreas: new Set([{ id: '10', name: 'エネルギー・インフラストラクチャー' }]),
      },
      {
        id: '3',
        name: 'コーポレート',
        practiceAreas: new Set([{ id: '11', name: '会社法/コーポレート・ガバナンス' }]),
      },
      { id: '4', name: '危機管理', practiceAreas: new Set([{ id: '12', name: '危機管理' }]) },
      {
        id: '5',
        name: 'M&A',
        practiceAreas: new Set([{ id: '13', name: 'M&A' }]),
      },
    ];
  }

  get itemsForSecondColumn(): PracticeAreaGroup[] {
    return [
      {
        id: '6',
        name: '事業再生・倒産',
        practiceAreas: new Set([{ id: '16', name: '破産' }]),
      },
      {
        id: '7',
        name: '争訴・紛争解決',
        practiceAreas: new Set([
          { id: '18', name: '国内訴訟・紛争' },
          { id: '19', name: '国際訴訟・紛争' },
        ]),
      },
      {
        id: '8',
        name: '競争法・独禁法・消費者法',
        practiceAreas: new Set([
          { id: '20', name: '競争法・独占禁止法' },
          { id: '21', name: '消費者関連法' },
        ]),
      },
      { id: '9', name: '労働法', practiceAreas: new Set([{ id: '22', name: '労働法' }]) },
      {
        id: '10',
        name: '税務・ウェルスマネジメント',
        practiceAreas: new Set([{ id: '23', name: '税務・ウェルスマネジメント' }]),
      },
      {
        id: '11',
        name: '知財・IT',
        practiceAreas: new Set([
          { id: '24', name: '知財' },
          { id: '25', name: '情報保護' },
          { id: '46', name: 'IT' },
        ]),
      },
    ];
  }

  get itemsForThirdColumn(): PracticeAreaGroup[] {
    return [
      {
        id: '12',
        name: '規制法対応',
        practiceAreas: new Set([
          { id: '28', name: '薬事・医事' },
          { id: '29', name: '自動車・船舶・航空' },
        ]),
      },
      {
        id: '13',
        name: '通商法・国際業務',
        practiceAreas: new Set([{ id: '34', name: '通商法' }]),
      },
      {
        id: '14',
        name: '行政法',
        practiceAreas: new Set([{ id: '47', name: '行政法' }]),
      },
      {
        id: '15',
        name: '刑事',
        practiceAreas: new Set([{ id: '48', name: '刑事' }]),
      },
    ];
  }
}
</script>
<style lang="scss" scoped>
@import '@/../node_modules/vuetify/src/styles/styles.sass';

.practice-area-group-index {
  > .headline {
    padding: 50px 0px 34px;

    > .text {
      font-size: 28px;
      color: #000000de;
    }
  }

  > .grouplist {
    padding-left: 0px;
    @media #{map-get($display-breakpoints, 'sm-only')} {
      .column {
        flex-basis: 100%;
      }
    }
  }

  > .grouplist > .columns > .column {
    width: 340px;
    margin-right: 40px;

    &:last-child {
      margin-right: 0px;
    }
  }

  > .grouplist > .columns > .column > .group {
    list-style: none;

    > .name {
      margin: 16px 0;
      font-size: 20px;
      color: #000000de;
      letter-spacing: 0.1em;
    }

    > .separator {
      border-top: 1px solid #e0e0e0;
      border-bottom: none;
      border-right: none;
      border-left: none;
    }
  }

  > .grouplist > .columns > .column > .group > .practiceareas {
    padding-left: 0;
    margin-top: 4px;
    margin-bottom: 56px;
  }

  > .grouplist > .columns > .column > .group > .practiceareas > .area {
    list-style: none;
    font-size: 16px;
    color: #000000de;
    margin: 0;
    letter-spacing: 0.05em;
  }

  > .grouplist > .columns > .column > .group > .practiceareas > .area > .link {
    color: #000000de;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    > .text {
      margin: 0;
    }

    > .chevron {
      margin: 0;
    }
  }

  > .grouplist > .columns > .column > .group > .practiceareas > .area > a.link:hover {
    color: #1976d2;
    font-weight: bold;
  }
}
</style>
